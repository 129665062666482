import styled from 'styled-components';

import { colors } from '../../styles/atoms/colors';

export const ArrowContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 24px;
  ${props => props.position}

  .swiper-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${colors.base.white};
    border: 1px solid ${colors.gray[300]};
    border-radius: 50%;
    width: 48px;
    height: 48px;
    cursor: pointer;

    svg {
      color: ${colors.gray[500]};
      width: 24px;
      font-size: 24px;
    }
  }

  .swiper-button.new {
    z-index: 1;
  }

  .swiper-button:not(.new) {
    z-index: 2;
  }

  .swiper-button::after {
    content: '';
    background-image: none;
  }
`;
