import React, { useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { css } from 'styled-components';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css';
import SwiperCore, { Navigation } from 'swiper';
import { SplitStyles } from '../../styles/Components/TestimonialCarousel/TestimonialStyles';

import { ArrowContainer } from './SwiperArrowsStyle';
import AuthorCard from '../../molecules/AuthorCard/AuthorCard';
import Icon from '../../styles/atoms/icons';

SwiperCore.use([Navigation]);

const SplitCarousel = ({ testimonial, theme }) => {
  const splitTestimonials = [testimonial];
  const prevRef = useRef(null);
  const nextRef = useRef(null);

  return (
    <SplitStyles theme={theme}>
      <div className="testimonials">
        {splitTestimonials.map((item, index) => (
          <Swiper
            navigation
            spaceBetween={32}
            slidesPerView="auto"
            className="split-swiper"
            loop
            key={`swiper_${index}`}
            onInit={swiper => {
              swiper.params.navigation.prevEl = prevRef.current;
              swiper.params.navigation.nextEl = nextRef.current;
              swiper.navigation.init();
              swiper.navigation.update();
            }}
          >
            {item.map(info => (
              <SwiperSlide key={info?.internalName}>
                <div className="split-card">
                  <div className="quote">
                    <div dangerouslySetInnerHTML={{ __html: info?.quote }} />
                  </div>
                  <AuthorCard
                    headshot={info?.person?.headshot}
                    first={info?.person?.firstName}
                    last={info?.person?.lastName}
                    authorRole={info?.person?.role}
                  />
                </div>
              </SwiperSlide>
            ))}
            <ArrowContainer
              position={css`
                position: absolute;
                bottom: 0;
                left: 50px;
              `}
            >
              <div className="swiper-button new" ref={prevRef}>
                <Icon id="FiArrowLeft" />
              </div>
              <div className="swiper-button new" ref={nextRef}>
                <Icon id="FiArrowRight" />
              </div>
            </ArrowContainer>
          </Swiper>
        ))}
      </div>
    </SplitStyles>
  );
};

export default SplitCarousel;
