import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css';
import SwiperCore, { Navigation } from 'swiper';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { breakpoints } from '../../styles/atoms/breakpoints';

import { StandardStyles } from '../../styles/Components/TestimonialCarousel/TestimonialStyles';
import AuthorCard from '../../molecules/AuthorCard/AuthorCard';

SwiperCore.use([Navigation]);

const StandardCarousel = ({ testimonial, theme }) => {
  const isTablet = !useMediaQuery(`(min-width: ${breakpoints.lg})`);
  const isMobile = !useMediaQuery(`(min-width: ${breakpoints.md})`);
  const splitTestimonials = [testimonial];

  return (
    <StandardStyles theme={theme}>
      <Container>
        <Row>
          <Col lg={12}>
            <div className="testimonials">
              {splitTestimonials.map((item, index) => (
                <Swiper
                  navigation={true}
                  spaceBetween={32}
                  slidesPerView={isMobile ? 1 : isTablet ? 2 : 3}
                  className="standard-swiper"
                  loop={true}
                  key={`swiper_${index}`}
                >
                  {item.map(info => (
                    <SwiperSlide key={info?.internalName}>
                      <div className="standard-card">
                        <div className="quote">
                          <div
                            dangerouslySetInnerHTML={{ __html: info?.quote }}
                          />
                        </div>
                        <AuthorCard
                          headshot={info?.person?.headshot}
                          first={info?.person?.firstName}
                          last={info?.person?.lastName}
                          authorRole={info?.person?.role}
                          size="sm"
                        />
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              ))}
            </div>
          </Col>
        </Row>
      </Container>
    </StandardStyles>
  );
};

export default StandardCarousel;
