import React from 'react';
import { Col } from 'react-bootstrap';
import CallToAction from '../../molecules/CallToAction/CallToAction';
import Section from '../../molecules/Section/Section';
import {
  Heading,
  HeadingWrapper,
  Kicker,
  Subhead,
  Wrapper,
} from '../../styles/Components/TestimonialCarousel/TestimonialStyles';
import { colors } from '../../styles/atoms/colors';
import { getTag } from '../../utils/getTag';
import CareersCarousel from './CareersCarousel';
import SplitCarousel from './SplitCarousel';
import StandardCarousel from './StandardCarousel';

const TestimonialCarousel = ({
  component: {
    bgColor,
    heading,
    headingKicker,
    headingKickerTag,
    headingTag,
    id,
    showCsLink,
    subhead,
    testimonial,
    variant,
  },
  slug,
}) => {
  const BACKGROUND_MAPPING = {
    White: colors.base.white,
    Gray: colors.base.heroSurface,
    Charcoal: colors.gray[900],
  };

  const determineBackgroundClass = () =>
    bgColor ? BACKGROUND_MAPPING[bgColor] : colors.base.white;

  return (
    <Section id={id} bgColor={determineBackgroundClass()}>
      <Wrapper variant={variant}>
        <Col
          lg={variant === 'Split' ? 5 : 12}
          md={variant === 'Split' ? 5 : 12}
        >
          <HeadingWrapper variant={variant}>
            {headingKicker && (
              <Kicker as={headingKickerTag ? getTag(headingKickerTag) : 'p'}>
                {headingKicker}
              </Kicker>
            )}
            {heading && (
              <Heading as={headingTag ? getTag(headingTag) : 'h2'}>
                {heading}
              </Heading>
            )}
            {subhead && (
              <Subhead dangerouslySetInnerHTML={{ __html: subhead }} />
            )}
            {slug !== 'demo' && showCsLink && (
              <CallToAction
                value="View all case studies"
                variant={variant === 'Split' ? 'primary' : 'linkColor'}
                link="/case-studies"
                icon="right"
              />
            )}
          </HeadingWrapper>
        </Col>
        {variant === 'Split' && (
          <Col lg={7} md={7}>
            <SplitCarousel
              testimonial={testimonial}
              theme={determineBackgroundClass()}
            />
          </Col>
        )}
      </Wrapper>
      {variant === 'Default' && (
        <StandardCarousel
          testimonial={testimonial}
          theme={determineBackgroundClass()}
        />
      )}
      {variant === 'Focused' && <CareersCarousel testimonial={testimonial} />}
    </Section>
  );
};

export default TestimonialCarousel;
